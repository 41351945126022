import React, {useEffect, useRef, useState} from "react";
import {PdfDocArrow, PdfDocIcon} from "../../svg-icons/icons";
import {useDispatch, useSelector} from "react-redux";
import {agreementTitleMap} from "../../../utils/formatter";
import {Button, Collapse, Modal, Select} from "antd";
import SendPdfForm from "../../Pages/Dashboard/SendPdfForm";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {
    getAgentTemplate,
    getTemplate,
    pdfmanipluate,
    sendagentPdfClient,
    sendPdfClient,
} from "../../../redux/services";
import moment from "moment";

const ShareDocuments = () => {
    const { template } = useSelector((state) => state.client);
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => setIsOpen(!isOpen);
    const [showManipluationForm, setShowManipluationForm] = useState(false); // Control visibility of PDFForm
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data } = useSelector((state) => state?.user);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isContractModalVisible, setIsContractModalVisible] = useState(false);
    const formRef = useRef(null); // Ref to handle form submission manually
    const [client, setClient] = useState(null); // null for new, object for edit
    const [agent, setAgent] = useState(null); // null for new, object for edit

    const [itemToDelete, setItemToDelete] = useState(null);
    const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
    const [selectedPDF, setSelectedPDF] = useState(null);
    const [pdftData, setPdftData] = useState(null); // New state for re-render

    useEffect(() => {
        if (selectedPDF) {
            setShowManipluationForm(true);
            setIsContractModalVisible(true);
        }
    }, [selectedPDF]);

    useEffect(() => {
        if (data.role === "agent") {
            dispatch(getAgentTemplate());
        }
        if (data.role === "broker") {
            dispatch(getTemplate());
        }

    }, []);

    const formatData = (data) => {
        const dateString = data;
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        return moment(`${year}-${month}-${day}`, "YYYY-MM-DD");
    }

    const handlePDFSelect = (value) => {
        let document = template.find((t)=> t.template_name === value)
        if (document) {
            let start = formatData(document.metadata.start_date);
            let end = formatData(document.metadata.end_date);
            const data = {...document.metadata, start_date: start, end_date: end}
            data.template_name = document.template_name
            setSelectedPDF(data);
        } else {
            let defaultDoc = {template_name: value}
            setSelectedPDF(defaultDoc);
        }
    };

    const handlePdfSend = () => {
        if (selectedPDF) {
            setShowManipluationForm(true);
        }
    };

    const handleOkClick = (pdf) => {
        let pdff = pdftData || pdf
        let filtredPdftData = Object.fromEntries(Object.entries(pdff).filter(([_, v]) => v !== undefined));
        const main = ['residential_buyer_tenant_agreement', 'residential_buyer_tenant_short_agreement', 'information_brokerage_agreement']
        let _pdf = selectedPDF?.template_name
        main.forEach((d)=>{
            if(selectedPDF?.template_name.includes(d)){
                _pdf = d
            }
        })

        if (data?.role === 'agent') {
            dispatch(sendagentPdfClient({
                id: id,
                pdf_name: _pdf,
                ...filtredPdftData
            }));
        } else if (data?.role === 'broker') {
            dispatch(sendPdfClient({
                id: id,
                pdf_name: _pdf,
                ...filtredPdftData
            }));
        } else if (data?.role === 'client' || data?.role === 'contact') {
            pdftData.id = selectedPDF?.id;
            dispatch(pdfmanipluate(filtredPdftData));
        }

        setSelectedPDF(null);
        setIsContractModalVisible(false);
        setPdftData(null);
        setSelectedPDF(null);
        setIsContractModalVisible(false);
        setShowManipluationForm(false);
    };

    const handlePdfManipulation = (pdfdata) => {
        setPdftData(pdfdata)
        handleOkClick(pdfdata)
        setIsContractModalVisible(false);
        setShowManipluationForm(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setClient(null);
        setAgent(null);
        setIsPDFModalVisible(false);
        setItemToDelete(null);
        setIsContractModalVisible(false);
        setShowManipluationForm(false)
    };

    return (
        <div className="share-documents" style={{ width: '100%' }}>
            <h3 style={{ marginBottom: "16px" }}>Share Documents</h3>
            <div onClick={toggleCollapse} style={{ display: "flex", justifyContent: "space-between", alignItems: "center",
                cursor: "pointer", padding: "11px 32px", backgroundColor: "#fff", borderRadius: "6px", boxShadow: '0 3px 6px rgba(0, 0, 0, 0.25)' }}>
                <PdfDocIcon />
                <span style={{ width: '100%', textAlign: 'center', fontWeight: 'bold'  }}> Documents</span>
                <span style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0)", transition: "transform 0.3s" }}><PdfDocArrow /></span>
            </div>
            {isOpen && (
                <ul
                    style={{
                        listStyleType: "none",
                        padding: "16px",
                        margin: 0,
                        backgroundColor: "#fff",
                        marginTop: "7px",
                        borderRadius: "6px",
                        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.25)",
                        maxHeight: "200px", // Set maximum height
                        overflowY: "auto", // Enable vertical scrolling
                    }}
                >
                    {Object.entries(agreementTitleMap).map(([key, title]) => (
                        <li
                            key={key}
                            style={{ padding: "8px 0", cursor: "pointer" }}
                            onClick={() => handlePDFSelect(key)} // Pass the key on click
                        >
                            {title}
                        </li>
                    ))}
                    {template.map((option) => (
                        <li
                            key={option.id}
                            style={{ padding: "8px 0", cursor: "pointer" }}
                            onClick={() => handlePDFSelect(option.template_name)}
                        >
                            {option?.title || option.template_name}
                        </li>
                    ))}
                </ul>

            )}

            <Modal
                title={showManipluationForm ? `Form for ${selectedPDF?.title ? selectedPDF?.title  : selectedPDF?.template_name}  ` : "Select a PDF"}
                visible={isContractModalVisible}
                onOk={handlePdfSend}
                className="add-new-agent"
                footer={
                    showManipluationForm
                        ? [
                            <Button key="canel" onClick={handleCancel}>
                                cancel
                            </Button>,
                        ]
                        : [

                            <Button key="ok" onClick={handlePdfSend}>
                                OK
                            </Button>,
                        ]
                }
                onCancel={handleCancel}
                okButtonProps={{ disabled: !selectedPDF && !showManipluationForm }} // Disable OK if no selection and form not showing
            >
                    <SendPdfForm
                        formRef={formRef} // Pass formRef to handle manual submission
                        onSubmit={handlePdfManipulation}
                        selectedPDF={selectedPDF}
                    />
            </Modal>
        </div>
    );
};

export default ShareDocuments;
