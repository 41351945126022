import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  Earn,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  VerifyIdentity,
  EmailConfirmation,
  Landing,
  LogoutCompo,
  Client,
  PricingAgent,
  Faq,
  Services,
  TermsConditions,
  PrivacyPolicy,
  ContactSales,
  Help,
  MainDashboard,
  PDFManiplulation,
  ClientMobile,
  ClientViewMobile,
  AgentMobile,
  AgentsCleintMobile,
  DocumentCards,
  DocumentCard,
  ClientDocument,
  TemplateMobile,
  TemplateDesktop,
  Agent, AdminDashboard, BrokerAgents
} from "./lazyRoute";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";
import ClientView from "../Components/Pages/ClientView";
import AdminAgent from "../Components/AdminComponents/AdminAgent";
import AgentViewMobile from "../mobile/Components/AgentView";
import DocView from "../Components/Pages/DocView";

// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity,
  docView
} = publicRouteObj;

const {
  authLanding,
  logout,
  pdf,
} = primaryRoutes;

export const commonRoutesData = [
  {
    path: primaryRoutes.faq,
    component: <Faq />,
    mobileComponent: <Faq />,
  },
];

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
    mobileComponent: <Landing />,
  },
  {
    path: login,
    component: <Login />,
    mobileComponent: <Login />,
  },
  {
    path: `${docView}`,
    component: <DocView />,
    mobileComponent: <DocView />,
  },

  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
    mobileComponent: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
    mobileComponent: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
    mobileComponent: <CreateAcount />,
  },

  {
    path: emailVerify,
    component: <VerifyEmail />,
    mobileComponent: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
    mobileComponent: <EmailConfirmation />,
  },

  {
    path: identity,
    component: <Identity />,
    mobileComponent: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
    mobileComponent: <VerifyIdentity />,
  },
  {
    path: primaryRoutes.pricing_agent,
    component: <PricingAgent />,
    mobileComponent: <PricingAgent />,
  },
  {
    path: primaryRoutes.services,
    component: <Services />,
    mobileComponent: <Services />,
  }, {
    path: primaryRoutes.faq,
    component: <Faq />,
    mobileComponent: <Faq />,
  },
  {
    path: primaryRoutes.terms_conditions,
    component: <TermsConditions />,
    mobileComponent: <TermsConditions />,
  },
  {
    path: primaryRoutes.privacy_policy,
    component: <PrivacyPolicy />,
    mobileComponent: <PrivacyPolicy />,
  },
  {
    path: primaryRoutes.contact_sales,
    component: <ContactSales />,
    mobileComponent: <ContactSales />,
  },
  {
    path: primaryRoutes.help,
    component: <Help />,
    mobileComponent: <Help />,
  },
];

// Private routing array

const {
  home,
  agents,
  documents,
  earn,
  client,
  maindashboard,
  myaccount,
  admindashbaord,
  inkadmin,
  allbrokers,
  allagents,
  allcontacts,
  alldocuments,
  adminmyaccount,
  templatemobile
} = sidebarRouteObj;


export const privateRoutesData = [
  {
    path: ``,
    component: <MainDashboard />,
    mobileComponent: <MainDashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
    mobileComponent: <LogoutCompo />,
  },
  {
    path: authLanding,
    component: <Landing />,
    mobileComponent: <Landing />,
  },
  {
    path: `agents/:id`,
    component: <Agent />,
    mobileComponent: <AgentViewMobile />,
  },
  {
    path: `agents/:agent_id/contacts/:id`,
    component: <Agent />,
    mobileComponent: <AgentsCleintMobile />,
  },
  {
    path: `contacts/:id`,
    component: <ClientView />,
    mobileComponent: <ClientViewMobile />,
  },
  {
    path: `contacts/:id/documents`,
    component: <ClientDocument />,
    mobileComponent: <ClientDocument />,
  },
  {
    path: `${home}`,
    component: <MainDashboard />,
    mobileComponent: <MainDashboard />,
  },
  {
    path: `${agents}`,
    component: <Dashboard />,
    mobileComponent: <AgentMobile />,
  },
  {
    path: `${documents}`,
    component: <DocumentCard />,
    mobileComponent: <DocumentCards />,
  },
  {
    path: `${templatemobile}`,
    component: <TemplateDesktop />,
    mobileComponent: <TemplateMobile />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
    mobileComponent: <Earn />,
  },
  {
    path: `${myaccount}`,
    component: <Earn />,
    mobileComponent: <Earn />,
  },
  {
    path: `${client}`,
    component: <Client />,
    mobileComponent: <ClientMobile />,
  },
  {
    path: `${maindashboard}`,
    component: <MainDashboard />,
    mobileComponent: <MainDashboard />,
  },
  {
    path: `${pdf}/:id`,
    component: <PDFManiplulation />,
    mobileComponent: <PDFManiplulation />,
  },
  {
    path: primaryRoutes.faq,
    component: <Faq />,
    mobileComponent: <Faq />,
  },
  {
    path: `${docView}`,
    component: <DocView />,
    mobileComponent: <DocView />,
  },
];

export const adminRoutes = [
  {
    path: `${admindashbaord}`,
    component: <AdminDashboard />,
    mobileComponent: <AdminDashboard />,
  },
  {
    path: `${inkadmin}`,
    component: <Earn />,
    mobileComponent: <Earn />,
  },
  {
    path: `${allbrokers}`,
    component: <AdminAgent />,
    mobileComponent: <AdminAgent />,
  },
  {
    path: `${allagents}`,
    component: <AdminAgent />,
    mobileComponent: <AdminAgent />,
  },
  {
    path: `inkadmin/agents/:id`,
    component: <BrokerAgents />,
    mobileComponent: <BrokerAgents />,
  },
  {
    path: `inkadmin/contacts/:id`,
    component: <Agent />,
    mobileComponent: <Agent />,
  },
  {
    path: `${allcontacts}`,
    component: <AdminAgent />,
    mobileComponent: <AdminAgent />,
  },
  {
    path: `${alldocuments}`,
    component: <Portfolio />,
    mobileComponent: <Portfolio />,
  },
  {
    path: `${adminmyaccount}`,
    component: <Earn />,
    mobileComponent: <MainDashboard />,
  },

];

export const freeRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
    mobileComponent: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
    mobileComponent: <LogoutCompo />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
    mobileComponent: <Earn />,
  },
  {
    path: `${docView}`,
    component: <DocView />,
    mobileComponent: <DocView />,
  },
  {
    path: primaryRoutes.faq,
    component: <Faq />,
    mobileComponent: <Faq />,
  },
]