import React from "react";
import { useParams } from 'react-router-dom';

function DocView() {
    const { s3Key } = useParams();
    const { id } = useParams();

    React.useEffect(() => {
        const decodedUrl = decodeURIComponent(s3Key);
        const currentUrl = window.location.href;
        // Extract the path part (filename) after "/docview/"
        const urlParts = currentUrl.split("/docview/");

        const dfdfd = urlParts[1]
        console.log('updatreddddddd', dfdfd, urlParts)

        const awsS3PresignedUrl = `https://stage-ink-agent.s3.amazonaws.com/uploads/${dfdfd}`;
        window.location.href = awsS3PresignedUrl;

        if (s3Key) {
            // Construct the AWS S3 presigned URL

            // Redirect the user to the presigned URL
            window.location.href = awsS3PresignedUrl;
        }
    }, [s3Key]);

    return (
        <div>
            <h1>Redirecting to the document...</h1>
        </div>
    );
}

export default DocView;
