import React from 'react';
import { Modal, Button } from 'antd';

const DeleteModal = ({ isVisible, onConfirm, onCancel, itemName }) => {
  return (
    <Modal
      title="Confirm Deletion"
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Delete"
      cancelText="Cancel"
      closable={false} // Optionally remove the close button
    >
      <p>Are you sure you want to delete <strong>{itemName}</strong> from your contact list? This action cannot be undone.
        Additionally, all contracts associated with this contact will be deleted.</p>
    </Modal>
  );
};

export default DeleteModal;