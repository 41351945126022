export const publicRouteObj = {
  login: "login",
  changePassword: "changePassword",
  resetPassword: "resetPassword",
  docView: "docview/:url",
  createAccount: "register",
  emailVerify: "emailVerify",
  emailConfirmation: "emailConfirmation",
};

// Sidebar obj
export const sidebarRouteObj = {
  home: "home",
  inkadmin: "inkadmin",
  agents: "agents",
  documents: "documents",
  earn: "my-account",
  client: "contacts",
  docView: "docview/:url",
  maindashboard: "dashboard",
  templatemobile: "templates",
  myaccount: "my-account",
  admindashbaord: "inkadmin/admin-dashboard",
  allbrokers: "inkadmin/all-brokers",
  allagents: "inkadmin/all-agents",
  alldocuments: "inkadmin/all-docs",
  allcontacts: "inkadmin/all-contacts",
  adminmyaccount: "inkadmin/my-account",
};

const {
  home,
  agents,
  documents,
  earn,
  client,
  maindashboard,
  myaccount,
} = sidebarRouteObj;

export const defaultActiveSidebarObj = {
  [home]: ["1", "home", "homeIcon"],
  [agents]: ["1", "agents", "earnIcon"],
  [documents]: ["2", "documents", "portIcon"],
  [maindashboard]: ["5", "maindashboard", "maindashboardIcon"],
  [client]: ["4", "client", "earnIcon"],
  [earn]: ["3", "earn", "earnIcon"],
  [myaccount]: ["6", "myaccount", "earnIcon"],
};

export const routeExists = [
  home,
  agents,
  documents,
  earn,
  client,
  maindashboard,
  myaccount,
];

export const primaryRoutes = {
  verification: "verification",
  verificationCode: "verificationCode",
  overview: "overview",
  setting: "setting",
  deviceManagement: "device-management",
  accountActivity: "account-activity",
  order: "order",
  landing: "landing",

  pricing_agent : "pricing-agent",
  services : "services",
  faq : "faq",
  terms_conditions :'terms-conditions',
  privacy_policy : 'privacy-policy',
  contact_sales : 'contact-sales',
  help: 'Help',


  notfication: "notfication",
  viewAll: "view-all",
  viewHistory: "view-history",
  addressManagement: "address-management",
  intro: "intro/:id",
  introWithoutId: "intro",
  rewardViewAll: "reward_view_all",
  referralViewAll: "referral_view_all",
  kycRetail: "kycRetail",
  kycCorporate: "kycCorporate",
  convertMNG: "convertMNT",
  convertHistory: "convertHistory",
  authLanding: "authLanding",
  logout: "logout",
  cryoto_deposit: "cryoto_deposit",
  bank_deposit: "bank_deposit",
  fTransaferHistory: "future_transferHistory",
  chbtoken: "chbtoken",
  ourSubscription: "ourSubscription",
  newsDetail: "newsDetail",
  pdf: "pdf",
};

const {
  setting,
  order,
  addressManagement,
  viewAll,
  viewHistory,
  rewardViewAll,
  introWithoutId,
  intro,
  kycRetail,
  kycCorporate,
  fTransaferHistory,
  pdf,
} = primaryRoutes;

export const headerHeadingObj = {
  [home]: "INK AGENTS",
  [documents]: "Documents",
  [earn]: "My Account",
  [client]: "Client",
  [setting]: "Settings",
  [pdf]: "pdf",
  [order]: "Orders",
  [addressManagement]: "Address Management",
  [viewAll]: "My Earnings",
  [viewHistory]: "History",
  [rewardViewAll]: "Reward History",
  [kycRetail]: "KYC",
  [kycCorporate]: "KYC",
  [fTransaferHistory]: "Futures Order",
};
export const headerHeadingMNObj = {
  [home]: "INK AGENTS",
  [documents]: "Documents",
  [maindashboard]: "Dashboard",
  [earn]: "My Account",
  [client]: "Client",
  [setting]: "Тохиргоо",
  [myaccount]: "My Account"
}

export const coinListRoutes = [ introWithoutId, intro];
