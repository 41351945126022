import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Button, Collapse, Modal, Table} from "antd";
import "./clientStyle.scss";
import {
    createClients,
    deleteSingleClient,
    getAgentClientpdf,
    getClientpdfListing,
    getpdfListing,
    getSingleClientList,
    getSingleClientListing,
    sendDocumentCopy,
    sendDocumentCopyAgent,
    sendResetToken,
    updateBrokerClient,
    updateClient
} from "../../../redux/services";
import "../Dashboard/dashboardStyle.scss"
import style from "../Dashboard/style.module.scss";
import ContactCard from "../../Common/ContactCard";
import {useParams} from "react-router";
import {startLoading, stopLoading} from "../../../redux/feature";
import StatusDropdown from "../../Common/StatusDropdown";
import {EditIcon} from "../../svg-icons/icons";
import ShareDocuments from "../../Common/ShareDocuments";
import ClientForm from "../Dashboard/clientForm";
import DeleteModal from "../Dashboard/deleteModal";
import {useNavigate} from "react-router-dom";

function ClientView() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { clientList } = useSelector((state) => state.client);
    const { singlePost } = useSelector((state) => state.client);
    const { data } = useSelector((state) => state?.user);
    const [fileUrl, setFileUrl] = useState("");
    const [isPdf, setIsPdf] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    const [client, setClient] = useState(null);
    const [iseditModalVisible, setIseditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToDeleteName, setItemToDeleteName] = useState(null);


    const agreementTitleMap = {
        residential_buyer_tenant_agreement: "Residential Buyer/Tenant Representative - Long",
        residential_buyer_tenant_short_agreement: "Residential Buyer/Tenant Representative - Short",
        information_brokerage_agreement: "Information about Brokerage Services",
    };
    useEffect(() => {
        if (data.role === "broker") {
            dispatch(
                getSingleClientList({
                    id: id,
                })
            );
            dispatch(
                getClientpdfListing({
                    id: id,
                })
            );
        } else if (data.role === "agent") {
            dispatch(
                getSingleClientListing({
                    id: id,
                })
            );
            dispatch(
                getAgentClientpdf({
                    id: id
                })
            );
        } else {}
    }, []);

    const showModal = (url) => {
        setFileUrl(url);
        setIsPdf(true); // Check if the URL is a PDF
        setIsModalVisible(true);
    };

    const handleSend = (id) => {
        if (data.role === "broker") {
            dispatch(sendDocumentCopy({
                id: id,
            }));
        } else if (data.role === "agent") {
            dispatch(sendDocumentCopyAgent({
                id: id,
            }));
        } else {}
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIseditModalVisible(false);
        setClient(null);
        setIsDeleteModalVisible(false);
        setItemToDelete(null);
    };

    const handleEditForm = (clientdata) => {
        const filteredData = Object.fromEntries(
            Object.entries(clientdata).filter(([key, value]) => value !== null)
        );

        if (data.role === "broker"){
            dispatch(updateBrokerClient(filteredData));
        } else if (data.role === "agent") {
            dispatch(updateClient(filteredData));
        }

        setIseditModalVisible(false);
    };

    const handleDelete = () => {
        dispatch(
            deleteSingleClient({
                id: itemToDelete,
            })
        );
        setIsDeleteModalVisible(false);
        navigate('/contacts')
    };

    const handleNewClient = (clientdata) => {
        dispatch(createClients(clientdata));
        setClient(null); // No client data for a new entry
        setIseditModalVisible(false);
    };

    const downloadFile = async (url) => {
        try {
            dispatch(startLoading());
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');

            const blob = await response.blob(); // Get the response as a Blob
            const blobUrl = URL.createObjectURL(blob); // Create a Blob URL

            // Open the Blob URL in a new window or tab to trigger the download
            window.open(blobUrl);

            // Clean up the Blob URL after a short delay
            setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
            dispatch(stopLoading());
        } catch (error) {
            console.error('Download failed:', error);
            dispatch(getpdfListing)
        }
    };

    const dataTblCol = [
        // { title: "Document Title", dataIndex: "agreement_name" },
        { title: "Document Title", dataIndex: "agreement_name", render:(text, record) => ( agreementTitleMap[record?.agreement_name] || record?.agreement_name ) },
        { title: "Client Name", dataIndex: "client_name",render:(text, record) => (record?.client_name == null ? 'Unprovided' : record.client_name) },
        { title: "Date Signed", dataIndex: "date_signed",render:(text, record) => (record.client_details?.client_signature_date) },
        {
            title: "Action",
            dataIndex: 'downloadUrl',
            render: (text, record) => (
                <div>
                    {/* Preview Button - opens modal */}
                    <Button  style={{
                        margin: "2px",
                        padding: "5px 15px",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        color: "rgba(0,0,0,.85)",
                    }} onClick={() => showModal(record.downloadUrl)}>
                        Preview
                    </Button>



                    {/* Download Button - triggers download from the S3 URL */}
                    <Button  style={{
                        margin: "2px",
                        padding: "5px 15px",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        color: "rgba(0,0,0,.85)",
                    }}
                             onClick={() => downloadFile(record.downloadUrl)}
                    >
                        Download
                    </Button>

                    {/* Download Button - triggers download from the S3 URL */}
                    <Button  style={{
                        margin: "2px",
                        padding: "5px 15px",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        color: "rgba(0,0,0,.85)",
                    }}
                             onClick={() => handleSend(record.id)}
                    >
                        Send
                    </Button>

                </div>
            ),
        },
    ];

    const handleDeleteClick = (clientdata) => {
        setIsDeleteModalVisible(true);
        setItemToDelete(clientdata.id);
        setItemToDeleteName(clientdata.name);
    };

    const handleResetToken = (data) => {
        if (data) {
            dispatch(
                sendResetToken({
                    id: data?.id,
                })
            );
        }
    };

    const handleEditClick = (clientdata) => {
        if (clientdata) {
            setIseditModalVisible(true);
            setClient(clientdata.id);
            if (data.role === "broker") {
                dispatch(
                    getSingleClientList({
                        id: clientdata.id,
                    })
                );
            } else if (data.role === "agent") {
                dispatch(getSingleClientListing({
                    id: clientdata.id
                }))
            }
        }
    };

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
        {data.role === 'broker' ?
            dispatch(
                getClientpdfListing({
                    id: id,
                    status: newStatus
                })
            )
        :
            dispatch(
                getAgentClientpdf({
                    id: id,
                    status: newStatus
                })
            );
        }

    };

    return (
        <>
            <div className="assetTable" style={{ marginTop: '30px' }}>
                <div className={`${style.home}`}>
                    <div className={`${style.home__inner}`}>
                        {data.role === "broker" || data.role === 'agent' ? (
                            <div className="pageMain">
                                <div className="page_title d-flex">
                                    <h4 style={{color: '#4B719C', textTransform: 'capitalize'}}>{singlePost?.data?.name == null ? 'Unprovided' : singlePost?.data?.name}</h4>
                                    <span>
                                    <Button
                                        onClick={() => handleDeleteClick(singlePost?.data)}
                                        className="delete-icon"> Delete</Button>
                                    <Button
                                        onClick={() => handleEditClick(singlePost?.data)}
                                        className="editIcon"> <EditIcon /> Edit
                                    </Button>
                                    <Button
                                        onClick={() => handleResetToken(singlePost?.data)}
                                        className="reset-icon"> Reset Password
                                    </Button>
                                    </span>
                                </div>
                                <div className="contact-list-card">
                                <ContactCard
                                    agent={singlePost?.data}
                                />
                                <ShareDocuments />
                                </div>
                                <div className={style.pricesData}>
                                    <div className={`${style.home__prices}`}>
                                        <div className="page_title d-flex"
                                        style={{paddingBottom: '0'}}>
                                            <h4 style={{ padding: '0', textTransform: 'capitalize'}}>{singlePost?.data?.name == null ? 'Unprovided' : singlePost?.data?.name}'s Documents</h4>
                                            <div style={{ alignItems: 'end', gap: '10px', marginBottom: '10px', paddingRight: '5px' }}>
                                                <StatusDropdown status={status} onStatusChange={handleStatusChange} />
                                            </div>
                                        </div>
                                        <div className="tableMain">

                                            <Table
                                                columns={dataTblCol}
                                                dataSource={clientList} // Use the fetched data
                                                pagination={false}
                                                className="portfolioTable"
                                            />
                                        </div>

                                        <Modal
                                            visible={isModalVisible}
                                            footer={null}
                                            onCancel={handleCancel}
                                            width={800} // Adjust width as necessary
                                        >
                                            {isPdf ? (
                                                <iframe
                                                    src={fileUrl}
                                                    style={{ width: '100%', height: '800px' }} // Adjust height as needed
                                                    title="PDF Preview"
                                                />
                                            ) : (
                                                <img
                                                    src={fileUrl}
                                                    alt="Preview"
                                                    style={{ width: '100%', height: 'auto' }} // Responsive image
                                                />

                                            )}
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            " "
                        )}
                    </div>
                </div>

                <Modal
                    title={client ? "Edit Contact" : "Add New Contact"}
                    visible={iseditModalVisible}
                    onCancel={handleCancel}
                    footer={null} // Disable default footer
                    className="add-new-agent"
                >
                    {client ? (
                        <ClientForm
                            client={singlePost.data}
                            extraData={true} // Pass the new variable here
                            onSave={handleEditForm}
                            onCancel={handleCancel}
                        />
                    ) : (
                        <ClientForm
                            client={client}
                            extraData={true} // Pass the new variable here
                            onSave={handleNewClient}
                            onCancel={handleCancel}
                        />
                    )}
                </Modal>
                <DeleteModal
                    isVisible={isDeleteModalVisible}
                    onConfirm={handleDelete}
                    onCancel={handleCancel}
                    itemName={itemToDeleteName || ""}
                />


            </div>
        </>
    );
}

export default ClientView;
